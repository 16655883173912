<template>
  <v-container>
    <v-overlay :value="loadingData" v-if="loadingData">
      <v-progress-circular color="primary" indeterminate size="164">
        <v-avatar size="70">
          <v-img src="@/assets/logo1.svg"> </v-img>
        </v-avatar>
      </v-progress-circular>
    </v-overlay>
    <add-achievement v-if="isInRole(0)" @addAchievement="getAchievements(id)"></add-achievement>
    <edit-achievement
      v-if="isInRole(0)"
      @editAchievement="n => editedItem(n)"
      @editDialog="n => (editDialog = n.value)"
      :currantDate="model.achievementDate"
      :currantTitle="model.title"
      :currantDescription="model.description"
      :id="model.achievementGuid"
      :editDialog="editDialog"
    ></edit-achievement>
    <v-bottom-navigation absolute style="z-index: 2;">
      <v-pagination
        class="py-1"
        v-model="page"
        :length="Math.ceil(achievements.length / perPage)"
        total-visible="15"
      ></v-pagination>
    </v-bottom-navigation>
    <v-row no-gutters v-if="achievements.length !== 0 || loadingData" style="margin-bottom: 100px">
      <v-card class="d-flex rounded-lg pa-4 my-6 mx-1" width="100%" flat>
        <v-text-field
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('writeWhatYouSearch')"
          outlined
          dense
          class="ma-auto"
        >
          <template v-slot:append-outer>
            <v-card-actions style="margin-top: -19px" class="pt-0"> </v-card-actions>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="back()" class="rounded-xl">
          {{ $t("back") }}
          <v-icon right> mdi-arrow-left </v-icon>
        </v-btn>
      </v-card>

      <v-col
        v-for="(item, index) in achievementsFilter"
        :key="index"
        class="px-0 py-1"
        cols="12"
        xl="3"
        lg="3"
        md="6"
        sm="12"
      >
        <v-card flat height="300" class="pa-0 mx-1">
          <v-card-title>
            {{ item.title }}
            <v-spacer />
            <v-menu bottom max-height="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <show-achievement :description="item.description"></show-achievement>
                <v-list-item @click="setModel(item)" v-if="isInRole(0)">
                  <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-pen</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-dialog
                  v-if="isInRole(0)"
                  v-model="deleteDialog"
                  transition="dialog-bottom-transition"
                  max-width="400"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="error" dark>
                        {{ $t("portfolio.Achievements.confirmDelete") }}</v-toolbar
                      >
                      <v-card-text>
                        <h3 class="pa-8">
                          {{
                            $t("portfolio.Achievements.confirmDeleteMessage").replace(
                              "title",
                              item.title
                            )
                          }}
                        </h3>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">{{ $t("close") }}</v-btn>
                        <v-btn color="error" @click="removeModel(item)">{{ $t("ok") }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <feedback-dialog :sourceGuid="item.achievementGuid" :sourceType="2" />
                <comments-dialog :sourceGuid="item.achievementGuid" :sourceType="2" :title="item.title" />
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle v-if="isInRole(2)">
            {{ item.student.studentFullName }}
          </v-card-subtitle>
          <v-card-text>
            <article v-html="item.description" class="achievement-description"></article>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap" style="position: absolute;bottom: 0;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  color="error"
                  label
                  class="ma-1 font-weight-bold"
                >
                  {{ item.achievementDate | moment("YYYY/MM/DD - h:mm a ") }}
                </v-chip>
              </template>
              <span>{{ $t(`portfolio.Achievements.date`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  color="success"
                  outlined
                  label
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1 font-weight-bold"
                >
                  {{ item.createdDate | moment("YYYY/MM/DD - h:mm a ") }}
                </v-chip>
              </template>
              <span>{{ $t(`portfolio.Achievements.createdDate`) }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card flat width="400px" max-width="100%" height="auto" class="pa-11 ma-auto text-center">
          <b class="text-center ma-auto">
            {{ $t("noData") }}
          </b>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import addAchievement from "@/components/portfolio/achievements/addAchievement.vue";
import EditAchievement from "@/components/portfolio/achievements/editAchievement.vue";
import ShowAchievement from "@/components/portfolio/achievements/showAchievement.vue";
import FeedbackDialog from '@/components/portfolio/feedback/feedbackDialog.vue';
import CommentsDialog from '../../components/portfolio/comments/commentsDialog.vue';

export default {
  components: {
     addAchievement, EditAchievement, ShowAchievement, FeedbackDialog, CommentsDialog
   },
  data() {
    return {
      userId: JSON.parse(localStorage.userData).guid,
      stuId: null,
      id: null,
      search: null,
      searchTag: null,
      model: {
        achievementGuid: null,
        title: null,
        description: null,
        achievementDate: null
      },
      overlay: false,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      show: false,
      achievements: [],
      tags: [],
      loadingData: false,
      message: "",
      page: 1,
      perPage: 8
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getAchievements(id) {
      this.loadingData = true;
      this.achievements = [];
      await this.axios
        .get(`Achievement/Get?studentGuid=${id}`)
        .then(response => {
          const { data } = response.data;
          // console.log(data);
          if (Array.isArray(data)) {
            data.forEach(achievement => {
              this.achievements.push(achievement);
            });
            if (this.achievements.length === 0) {
              this.$t("portfolio.messages.empty");
            }
          }
        })
        .catch(() => {
          // console.log(err);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    editedItem(item) {
      // eslint-disable-next-line prefer-const
      let achievement = this.achievements.find(a => a.achievementGuid === item.achievementGuid);
      if (achievement) {
        // console.log(item);
        achievement.description = item.description;
        achievement.title = item.title;
        achievement.achievementDate = item.achievementDate;
      }
    },
    setModel(item) {
      this.model = {
        achievementGuid: null,
        title: null,
        description: null,
        achievementDate: null
      };
      this.model.achievementDate = item.achievementDate;
      this.model.achievementGuid = item.achievementGuid;
      this.model.description = item.description;
      this.model.title = item.title;
      this.editDialog = true;
    },
    async removeModel(item) {
      // eslint-disable-next-line prefer-const
      let model = this.achievements.find(a => a.achievementGuid === item.achievementGuid);
      const modelIndex = this.achievements.findIndex(
        a => a.achievementGuid === item.achievementGuid
      );
      if (model) {
        this.deleteDialog = true;
        this.loadingData = true;
        await this.axios
          .post(`Achievement/Delete`, { achievementGuid: model.achievementGuid })
          .then(() => {
            this.achievements.splice(modelIndex, 1);
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
            this.deleteDialog = false;
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          })
          .finally(() => {
            this.loadingData = false;
          });
      }
    }
  },
  computed: {
    visiblePages() {
      return this.achievementsFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    achievementsFilter() {
      const data = this.achievements;

      // if (this.isLiveOnly) {
      //   data = data.filter((item) => item.isLive === true);
      // }

      if (this.search) {
        return data.filter(item => this.search
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v)));
      } else {
        return data;
      }
    }
  },
  created() {
    this.stuId = this.$route.params.stuId;
    this.id = this.stuId || this.userId;
    this.getAchievements(this.id);
    console.log(`Achievements Parent`);
  }
};
</script>

<style scoped>
/* .achievement-description {
  height: 160px !important;
  width: 95% !important;
  overflow: hidden !important;
  margin: auto !important;
} */
.html-text,
.achievement-description {
  width: 100%;
  /* height: 140px !important; */
  /* this code clamps based on specified lines */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: -webkit-box;
}
</style>
